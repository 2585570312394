// useCostTableRows.ts (or costTableRows.ts, etc.)
import { Typography } from '@mui/material';
import DecimalTextField from './NDCServiceFee';
import { weekday } from '../../../FlightDetailCard';
import format from 'date-fns/format';

import React, { useState } from 'react';
import { Box } from '@mui/material';
import { TFF } from '@tff/types';
import { useIntl } from 'react-intl';

interface TableRowData {
  label: string;
  show?: boolean;
  originalBooking: string | React.ReactNode;
  newBooking: string | React.ReactNode;
  surcharge: string | React.ReactNode;
  rowStyle?: React.CSSProperties;
  cellStyle?: {
    label?: React.CSSProperties;
    originalBooking?: React.CSSProperties;
    newBooking?: React.CSSProperties;
    surcharge?: React.CSSProperties;
  };
}

interface CreateRowsArgs {
  originalJourneys: TFF.Journey[];
  newBookingJourneys: TFF.Journey[];
  fareDifference: number;
  locale: { locale: string };
  serviceFee: string;
  setServiceFee: React.Dispatch<React.SetStateAction<string>>;
  taxAmount: number;
  penalty: number;
  baseAmount: number;
}

export const CreateCostTableRows = ({
  originalJourneys,
  newBookingJourneys,
  fareDifference,
  locale,
  serviceFee,
  setServiceFee,
  taxAmount,
  penalty,
  baseAmount,
}: CreateRowsArgs): TableRowData[] => {
  const numberFormatter = new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2 });
  const formatDate = (t: string): string | null => {
    if (t) {
      return format(new Date(t), 'dd.MM.yyyy');
    }
    return null;
  };
  const intl = useIntl();

  const possibleRows = [
    {
      label: `${intl.formatMessage({ id: 'rebook.directionOutbound' }).toUpperCase()}`,
      originalBooking: (
        <div>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {originalJourneys[0].Ond}
          </Typography>
          <Typography variant="body2">
            {weekday(originalJourneys[0].Segments[0].ArrivalTime, locale.locale).substring(0, 2)}.{` `}
            {formatDate(originalJourneys[0].Segments[0]?.DepartureTime)}
            {`  ·  `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(originalJourneys[0].Segments[0]?.DepartureTime),
            )}
            {` - `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(originalJourneys[0].Segments[originalJourneys[0].Segments.length - 1]?.ArrivalTime),
            )}
          </Typography>
        </div>
      ),
      newBooking: (
        <div>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {newBookingJourneys[0].Ond}
          </Typography>
          <Typography variant="body2">
            {weekday(newBookingJourneys[0].Segments[0].ArrivalTime, locale.locale).substring(0, 2)}.{` `}
            {formatDate(newBookingJourneys[0].Segments[0]?.DepartureTime)}
            {`  ·  `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(newBookingJourneys[0].Segments[0]?.DepartureTime),
            )}
            {` - `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(newBookingJourneys[0].Segments[newBookingJourneys[0].Stops]?.ArrivalTime),
            )}
          </Typography>
        </div>
      ),
      surcharge: '',
      cellStyle: {
        originalBooking: {
          backgroundColor: '#F5F5F5',
          whiteSpace: 'pre-line',
          borderColor: '#BABABA',
        },
        newBooking: {
          backgroundColor: '#E2F5FD',
          whiteSpace: 'pre-line',
          borderColor: '#BABABA',
        },
        label: {
          borderColor: '#BABABA',
        },
        surcharge: {
          borderColor: '#BABABA',
        },
      },
    },
    {
      label: `${intl.formatMessage({ id: 'rebook.directionInbound' }).toUpperCase()}`,
      show: originalJourneys.length > 1,
      originalBooking: (
        <div>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {originalJourneys[originalJourneys.length - 1].Ond}
          </Typography>
          <Typography variant="body2">
            {weekday(originalJourneys[originalJourneys.length - 1].Segments[0].ArrivalTime, locale.locale).substring(
              0,
              2,
            )}
            .{` `}
            {formatDate(originalJourneys[originalJourneys.length - 1].Segments[0]?.DepartureTime)}
            {`  ·  `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(originalJourneys[originalJourneys.length - 1].Segments[0]?.DepartureTime),
            )}
            {` - `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(
                originalJourneys[originalJourneys.length - 1].Segments[
                  originalJourneys[originalJourneys.length - 1].Segments.length - 1
                ]?.ArrivalTime,
              ),
            )}
          </Typography>
        </div>
      ),
      newBooking: (
        <div>
          <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
            {newBookingJourneys[newBookingJourneys.length - 1].Ond}
          </Typography>
          <Typography variant="body2">
            {weekday(newBookingJourneys[newBookingJourneys[0].Stops].Segments[0].ArrivalTime, locale.locale).substring(
              0,
              2,
            )}
            .{` `}
            {formatDate(newBookingJourneys[newBookingJourneys[0].Stops].Segments[0]?.DepartureTime)}
            {`  ·  `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(newBookingJourneys[newBookingJourneys.length - 1].Segments[0]?.DepartureTime),
            )}
            {` - `}
            {new Intl.DateTimeFormat('de-DE', { timeStyle: 'short' }).format(
              new Date(
                newBookingJourneys[newBookingJourneys.length - 1].Segments[
                  newBookingJourneys[newBookingJourneys.length - 1].Segments.length - 1
                ]?.ArrivalTime,
              ),
            )}
          </Typography>
        </div>
      ),
      surcharge: '',
      cellStyle: {
        originalBooking: {
          backgroundColor: '#F5F5F5',
          borderColor: '#BABABA',
          whiteSpace: 'pre-line',
        },
        newBooking: {
          backgroundColor: '#E2F5FD',
          borderColor: '#BABABA',
          whiteSpace: 'pre-line',
        },
        surcharge: {
          borderColor: '#BABABA',
        },
      },
    },
    {
      label: '',
      originalBooking: '',
      newBooking: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{intl.formatMessage({ id: 'ndc.rebookFareDifference' }).toUpperCase()}</span>
          <span>{numberFormatter.format(baseAmount || 0)}</span>
        </div>
      ),
      surcharge: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span></span>
          <span>{numberFormatter.format(baseAmount || 0)}</span>
        </div>
      ),
      cellStyle: {
        label: {
          border: 'none',
        },
        originalBooking: {
          backgroundColor: '#F5F5F5',
          borderColor: '#BABABA',
        },
        newBooking: {
          backgroundColor: '#E2F5FD',
          borderColor: '#BABABA',
        },
        surcharge: {
          borderColor: '#BABABA',
        },
      },
    },
    {
      label: '',
      originalBooking: '',
      newBooking: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{intl.formatMessage({ id: 'ndc.rebookTaxDifference' }).toUpperCase()}</span>
          <span>{numberFormatter.format(taxAmount)}</span>
        </div>
      ),
      surcharge: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span></span>
          <span>{numberFormatter.format(taxAmount)}</span>
        </div>
      ),
      cellStyle: {
        label: { border: 'none' },
        originalBooking: {
          backgroundColor: '#F5F5F5',
          borderColor: '#BABABA',
        },
        newBooking: {
          backgroundColor: '#E2F5FD',
          borderColor: '#BABABA',
        },
        surcharge: {
          borderColor: '#BABABA',
        },
      },
    },
    {
      label: '',
      originalBooking: '',
      newBooking: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{intl.formatMessage({ id: 'ndc.rebookPenalty' }).toUpperCase()}</span>
          <span>{numberFormatter.format(penalty)}</span>
        </div>
      ),
      surcharge: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span></span>
          <span>{numberFormatter.format(penalty)}</span>
        </div>
      ),
      cellStyle: {
        label: { border: 'none' },
        originalBooking: {
          backgroundColor: '#F5F5F5',
          borderColor: '#BABABA',
        },
        newBooking: {
          backgroundColor: '#E2F5FD',
          borderColor: '#BABABA',
        },
        surcharge: {
          borderColor: '#BABABA',
        },
      },
    },
    {
      label: '',
      originalBooking: '',
      newBooking: (
        <Box display="flex" justifyContent="space-between" alignItems="center" mb={-1} mt={-1}>
          <span>{intl.formatMessage({ id: 'ndc.rebookServiceFee' }).toUpperCase()}</span>
          <DecimalTextField setServiceFee={setServiceFee} serviceFee={serviceFee} />
        </Box>
      ),
      surcharge: '',
      cellStyle: {
        label: {
          border: 'none',
        },
        originalBooking: {
          backgroundColor: '#F5F5F5',
          borderColor: '#BABABA',
        },
        newBooking: {
          backgroundColor: '#E2F5FD',
          borderColor: '#BABABA',
        },
      },
    },
    {
      label: '',
      originalBooking: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{intl.formatMessage({ id: 'ndc.rebookSumCancelled' }).toUpperCase()}</span>
          <span>0,00</span>
        </div>
      ),
      newBooking: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{intl.formatMessage({ id: 'ndc.rebookSumNew' }).toUpperCase()}</span>
          <span>{numberFormatter.format(Number(fareDifference) + Number(serviceFee))}</span>
        </div>
      ),
      surcharge: (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <span>{intl.formatMessage({ id: 'ndc.rebookTotalToPay' }).toUpperCase()}</span>
          <span>{numberFormatter.format(Number(fareDifference) + Number(serviceFee))}</span>
        </div>
      ),
      cellStyle: {
        label: { border: 'none' },
        originalBooking: {
          backgroundColor: '#F5F5F5',
          borderColor: '#BABABA',
          fontWeight: 'bold',
        },
        newBooking: {
          backgroundColor: '#E2F5FD',
          borderColor: '#BABABA',
          fontWeight: 'bold',
        },
        surcharge: {
          backgroundColor: '#C6EAFB',
          borderColor: '#BABABA',
          fontWeight: 'bold',
        },
      },
    },
  ];

  const rowsToRender = possibleRows.filter(row => row.show !== false);

  return rowsToRender as TableRowData[];
};
