import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { commonStyles } from './NDCTableStyles';
import createdStore from '../../../../store';
import { TFF } from '@tff/types';
import { useTypedSelector } from '../../../../reducers';
import { CreateCostTableRows } from './CreateCostTableRows';
import { useState } from 'react';
import { useIntl } from 'react-intl';
interface props {
  fareDifference: number;
  originalJourneys: TFF.Journey[];
  newBookingJourneys: TFF.Journey[];
  taxAmount: number;
  penalty: number;
  baseAmount: number;
  orderDetails: TFF.OrderDetails;
}
interface TableRowData {
  label: string;
  originalBooking: string | React.ReactNode;
  newBooking: string | React.ReactNode;
  surcharge: string | React.ReactNode;
  rowStyle?: React.CSSProperties;
  cellStyle?: {
    label?: React.CSSProperties;
    originalBooking?: React.CSSProperties;
    newBooking?: React.CSSProperties;
    surcharge?: React.CSSProperties;
  };
}

const NDCCostOverview: React.FC<props> = ({
  fareDifference,
  originalJourneys,
  newBookingJourneys,
  taxAmount,
  penalty,
  baseAmount,
  orderDetails,
}) => {
  const reduxStore = createdStore();
  const { locale } = useTypedSelector(({ settings }) => settings);
  const passengerLength: number = Object.values(orderDetails.Passengers).filter(
    passenger => passenger.Type != 'INF',
  ).length;
  const [serviceFee, setServiceFee] = useState<string>(`${30 * passengerLength}`);
  const rows: TableRowData[] = CreateCostTableRows({
    originalJourneys,
    newBookingJourneys,
    fareDifference,
    locale,
    serviceFee,
    setServiceFee,
    taxAmount,
    penalty,
    baseAmount,
  });
  const inboundIndex = rows.findIndex(row => row.label === 'INBOUND');
  const adjustWidth = originalJourneys.length > 1 ? '18%' : '31.2%';
  const intl = useIntl();
  return (
    <Paper sx={{ ...commonStyles.tablePaper }}>
      <Typography sx={{ ...commonStyles.tableTitle }}>
        {' '}
        {intl.formatMessage({ id: 'ndc.rebookCostOverview' }).toUpperCase()}
      </Typography>
      <TableContainer>
        <Table aria-label="caption table">
          <TableHead>
            <TableRow>
              <TableCell
                align="center"
                sx={{
                  ...commonStyles.cellHeader,
                  width: '13%',
                }}
              />
              <TableCell
                align="center"
                sx={{
                  backgroundColor: '#F5F5F5',
                  ...commonStyles.cellHeader,
                  width: adjustWidth,
                }}
              >
                {intl.formatMessage({ id: 'ndc.rebookOriginalBooking' }).toUpperCase()}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  backgroundColor: '#E2F5FD',
                  ...commonStyles.cellHeader,
                  width: adjustWidth,
                }}
              >
                {intl.formatMessage({ id: 'ndc.rebookNewBooking' }).toUpperCase()}
              </TableCell>
              <TableCell
                align="center"
                sx={{
                  ...commonStyles.cellBody,
                  width: adjustWidth,
                }}
              >
                {intl.formatMessage({ id: 'ndc.rebookSurcharge' }).toUpperCase()}
              </TableCell>
            </TableRow>
            <br />
            <div style={{ marginBottom: 10, fontWeight: '700', fontSize: '18px' }}>
              {' '}
              {intl.formatMessage({ id: 'cancel.flights' }).toUpperCase()}
            </div>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow
                key={index}
                sx={{
                  borderTop: index === 0 ? '1px solid #BABABA' : undefined,
                  borderBottom: index === inboundIndex || index === rows.length - 2 ? '2px solid #737373' : undefined,
                  ...row.rowStyle,
                }}
              >
                <TableCell align="left" sx={row.cellStyle?.label}>
                  {row.label}
                </TableCell>
                <TableCell align="left" sx={row.cellStyle?.originalBooking}>
                  {row.originalBooking}
                </TableCell>
                <TableCell align="left" sx={row.cellStyle?.newBooking}>
                  {row.newBooking}
                </TableCell>
                <TableCell align="left" sx={row.cellStyle?.surcharge}>
                  {row.surcharge}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <caption style={{ display: 'flex', color: '#737373', fontWeight: 400, fontSize: '16px', marginTop: 20 }}>
        All Prices are in EURO
      </caption>
    </Paper>
  );
};

export default NDCCostOverview;
